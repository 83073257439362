@tailwind base;
@tailwind components;
@tailwind utilities;


*{

  @apply font-libre p-0 m-0 box-border
}

.customScrollBar .simplebar-scrollbar::before {
  @apply bg-primaryy-N50 !opacity-100
}

.customScrollBar .simplebar-track.simplebar-horizontal{
  @apply bg-primaryy-100  mx-7 xs:mx-8 sm:mx-20 md:mx-40 lg:mx-80 xl:mx-96 rounded-full 
}
button::before {
  content: none !important;
  display: none !important;
}
.custom-icon-color .drawer-header svg {
  color: white;
}


@font-face {
  font-family: 'AndoraModernSerif';
  src:  url('./fonts/Andora Modern Serif.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}