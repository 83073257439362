.btn::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 150%;
    height: 150%;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.4), rgba(69, 142, 112, 0));
    transform: translate(-50%, -50%) rotate(0deg);
    z-index: -1;
    animation: rotate-pulse 3s infinite ease-in-out;
    filter: blur(15px);
  }
  
  .btn.btninner {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    padding: 10px;
    font-size: 16px;
    color: white;
    background-color: #057a55;
    border: none;
    border-radius: 5px;
    z-index: 1;
    transition: all 0.55s ease;
    backdrop-filter: blur(40px);
    backface-visibility: hidden;
    overflow: hidden;
  }
  
  .btn.btninner:hover {
    background-color: #458b5f;
  }
  
  @keyframes rotate-pulse {
    0% {
      transform: translate(-50%, -50%) rotate(0deg) scale(1);
    }
    50% {
      transform: translate(-50%, -50%) rotate(180deg) scale(1.1);
    }
    100% {
      transform: translate(-50%, -50%) rotate(360deg) scale(1);
    }
  }
  